import React from 'react';
import './Contacts.scss'
import { schedule } from '../../Data/Schedule';
import ContactsMap from '../ContactsMap/ContactsMap';

const Contacts = () => {

    return (
        <section className='contacts_wrapper' id='contacts'>
            <div className="section_content">
                <h2 className='title_block_dark'>Контакти</h2>
                <div className='contacts_us_block'>
                    <div className="contacts_us_info">
                        <div className="schedule_weekdays">
                            <p className="contact_us_tit">Графік роботи:</p>
                            <p className="schedule_weekdays_time">ПН-ПТ: {schedule?.weekdays}</p>
                            <p className="schedule_weekdays_time">СБ: {schedule?.saturday}</p>
                            <p className="schedule_weekdays_time">НД: {schedule?.sandey}</p>
                        </div>
                        <div className="contact_us_adress">
                            <p className="contact_us_tit">Адреса:</p>
                            <p className="contact_us_adress">{schedule?.adress}</p>
                        </div>
                        <div className="contact_us_phone">
                            <p className="contact_us_tit">Телефон:</p>
                            {schedule?.phone?.map((elem,index) => {
                                return <a href={`tel:${elem}`} className="contact_us_phone" key={index}>{elem}</a>
                            })}
                        </div>
                        <div className="contact_us_email">
                            <p className="contact_us_tit">Email:</p>
                            <a href={`mailto:${schedule?.email}`} className="contact_us_phone">{schedule?.email}</a>
                        </div>
                    </div>
                    <div className="contacts_us_map">
                        <ContactsMap coordinates={schedule.location}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contacts;