import React from 'react';
import './HeadBlock.scss'
import { schedule } from '../../Data/Schedule';
import { Link } from 'react-scroll';

const HeadBlock = () => {
    return (
        <section className='head_block_section' id='main'>
            <div className='section_content head_block_content'>
                <div className='head_block_text'>
                    <p className='head_block_sec_txt'>Сайт компанії</p>
                    <h1 className="head_block_title">S.Laser</h1>
                    <p className='head_block_sec_txt'>Лазерне різання та гравіювання</p>
                </div>
                <div className='head_block_btn_content'>
                    <Link 
                        to='calculate'
                        className='head_block_btn'
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        >Розрахувати вартість
                    </Link>
                    {schedule?.phone?.map( (phone, index) => {
                        return <a href={`tel:${phone}`} key={index} className='head_block_btn'>Консультація</a>
                    })}
                    
                </div>
                <span className="head_lazer_left"></span>
                <span className="head_lazer_right"></span>
            </div>
        </section>
    );
};

export default HeadBlock;