import React from 'react';
import './Main.scss'
import HeadBlock from '../../Components/HeadBlock/HeadBlock';
import AboutUs from '../../Components/AboutUs/AboutUs';
import Materialies from '../../Components/Materialies/Materialies';
import WareBlock from '../../Components/WareBlock/WareBlock';
import Works from '../../Components/Works/Works';
import Calculate from '../../Components/Calculate/Calculate';
import Contacts from '../../Components/Contacts/Contacts';
import Partners from '../../Components/Partners/Partners';

const Main = () => {
    return (
        <section className='main_wrapper'>
            <main className='main_content'>
                <HeadBlock/>
                <AboutUs/>
                <Materialies/>
                <WareBlock/>
                <Works/>
                <Calculate/>
                <Contacts/>
                <Partners/>
            </main>
        </section>
    );
};

export default Main;