const schedule = {
    weekdays: "10:00 - 19:00",
    saturday: "За домовленістю",
    sandey: "За домовленістю",
    adress: "Київ, вул.Бережанська, 9",
    location: {
        lt:50.512348,
        ln:30.460225
    },
    phone: ["+38(066)-519-73-58"],
    email: 's.laser.art2024@gmail.com'
}

export {
    schedule
}