const navigation = [
    {
        link: "Про нас",
        href: "about"
    },
    {
        link: "Матеріали",
        href: "materiales"
    },
    {
        link: "Перелік виробів",
        href: "ware"
    },
    {
        link: "Наші роботи",
        href: "works"
    },
    {
        link: "Калькулятор",
        href: "calculate"
    },
    {
        link: "Контакти",
        href: "contacts"
    },
    {
        link: "Партнери",
        href: "partners"
    }
]

export {
    navigation
}