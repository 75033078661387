import React from 'react';
import './WareBlock.scss'
import { ware } from '../../Data/Ware';

const WareBlock = () => {
    return (
        <section className='ware_wrapper' id='ware'>
            <div className="section_content">
                <h2 className='title_block_dark'>Ми виробляємо</h2>
                <div className="ware_box">
                    {ware?.map((elem, index) => {
                        return <div className='ware_element' key={index}>
                                    <div className='ware_tit'>{elem.wareName}</div>
                                    <div className='ware_descr'>{elem.descr}</div>
                                </div>
                    })}
                </div>
            </div>
        </section>
    );
};

export default WareBlock;