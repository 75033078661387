import React, { useState } from 'react';
import './Calculate.scss'
import { Tabs, TabList, TabPanels, Tab, TabPanel, ChakraProvider,TabIndicator } from '@chakra-ui/react'
import { FaCaretLeft,FaCaretRight } from "react-icons/fa6";
import { materialPrice } from '../../Data/PriceList';

const Calculate = () => {
    const [productSizeAcrylic,setProductSizeAcrylic] = useState(1.5)
    const [acrylicMetric,setAcrylicMetric] = useState("")
    const [productSizePlywood,setProductSizePlywood] = useState(4)
    const [plywoodMetric,setPlywoodMetric] = useState('')
    const [productSizePolystyrene,setProductSizePolystyrene] = useState(0.5)
    const [polystyreneMetric,setPolystyreneMetric] = useState('')

    return (
        <section className='calculate_wrapper' id='calculate'>
            <div className="section_content">
                <h2 className='title_block_light'>Розрахунок вартості</h2>
                <div className="calculate_block">
                    <div className="calculate_text">
                        <p>Для Вашої зручності є змога скористатись калькулятором. Використовуючи його присутня можливість ознайомитись з прайс-листом по виду матеріалу, а також розрахувати вартість роботи відповідно до обраного матеріалу </p>
                    </div>
                    <div className="calculate_tabs">
                        <ChakraProvider>
                            <Tabs position="relative" variant="unstyled">
                                <TabList className='calculate_tab_list'>
                                    <Tab className='calculate_tab_elem' _selected={{ bg: '#ea8735'}}>Акрил</Tab>
                                    <Tab className='calculate_tab_elem' _selected={{ bg: '#ea8735'}}>Фанера</Tab>
                                    <Tab className='calculate_tab_elem' _selected={{ bg: '#ea8735'}}>Полістірол, ПЕТ</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <div className='product_box'>
                                            <p className='product_field_text'>Товщина: </p>
                                            <select className='product_size_select' onChange={(e) => setProductSizeAcrylic(e.target.value)}>
                                                {materialPrice?.acrylic.map((elem,index) => {
                                                    return <option value={elem?.size} key={index}>{elem?.size}мм</option>
                                                })}
                                            </select>
                                            <div className='metric_box'>
                                                <button className="metric_btn" onClick={() => setAcrylicMetric(prev => prev <= 1 ? prev : +prev - 1)}><FaCaretLeft/></button>
                                                <input className="tabs_metric_field"
                                                    type='number'
                                                    placeholder='П/М'
                                                    min={1} 
                                                    onChange={(e) => e.target.value >= 1 ? setAcrylicMetric(e.target.value) : setAcrylicMetric('')} 
                                                    value={acrylicMetric}/>
                                                <button className="metric_btn" onClick={() => setAcrylicMetric(prev => +prev + 1)}><FaCaretRight/></button>
                                            </div>
                                            <p className='product_field_text'>Вартість: </p>
                                            {materialPrice?.acrylic.map((elem,index) => {
                                                if (elem.size === +productSizeAcrylic) {
                                                    return <p className="price_text" key={index}>{elem?.priceMeter * acrylicMetric} грн.</p>
                                                }
                                            })}
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='product_box'>
                                            <p className='product_field_text'>Товщина: </p>
                                            <select className='product_size_select' onChange={(e) => setProductSizePlywood(e.target.value)}>
                                                {materialPrice?.plywood.map((elem,index) => {
                                                    return <option value={elem?.size} key={index}>{elem?.size}мм</option>
                                                })}
                                            </select>
                                            <div className='metric_box'>
                                                <button className="metric_btn" onClick={() => setPlywoodMetric(prev => prev <= 1 ? prev : +prev - 1)}><FaCaretLeft/></button>
                                                <input className="tabs_metric_field"
                                                    type='number'
                                                    min={1}
                                                    placeholder='П/М'
                                                    onChange={(e) => e.target.value >= 1 ? setPlywoodMetric(e.target.value) : setPlywoodMetric('')} 
                                                    value={plywoodMetric}/>
                                                <button className="metric_btn" onClick={() => setPlywoodMetric(prev => +prev + 1)}><FaCaretRight/></button>
                                            </div>
                                            <p className='product_field_text'>Вартість: </p>
                                            {materialPrice?.plywood.map((elem,index) => {
                                                if (elem.size === +productSizePlywood) {
                                                    return <p className="price_text" key={index}>{elem?.priceMeter * plywoodMetric} грн.</p>
                                                }
                                            })}
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='product_box'>
                                            <p className='product_field_text'>Товщина: </p>
                                            <select className='product_size_select' onChange={(e) => setProductSizePolystyrene(e.target.value)}>
                                                {materialPrice?.polystyrene.map((elem,index) => {
                                                    return <option value={elem?.size} key={index}>{elem?.size}мм</option>
                                                })}
                                            </select>
                                            <div className='metric_box'>
                                                <button className="metric_btn" onClick={() => setPolystyreneMetric(prev => prev <= 1 ? prev : +prev - 1)}><FaCaretLeft/></button>
                                                <input className="tabs_metric_field"
                                                    type='number'
                                                    min={1}
                                                    placeholder='П/М'
                                                    onChange={(e) => e.target.value >= 1 ? setPolystyreneMetric(e.target.value) : setPolystyreneMetric('')} value={polystyreneMetric}/>
                                                <button className="metric_btn" onClick={() => setPolystyreneMetric(prev => +prev + 1)}><FaCaretRight/></button>
                                            </div>
                                            <p className='product_field_text'>Вартість: </p>
                                            {materialPrice?.polystyrene.map((elem,index) => {
                                                if (elem.size === +productSizePolystyrene) {
                                                    return <p className="price_text" key={index}>{elem?.priceMeter * polystyreneMetric} грн.</p>
                                                }
                                            })}
                                        </div>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </ChakraProvider>
                    </div>
                    <div className="calculate_text">
                        <p>Для розрахунку вартості інших типів матеріалу ціна обумовлюється індивідуально.<br/> Мінімальне замовлення складає 250 грн.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Calculate;