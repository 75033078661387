import React from 'react';
import './AboutUs.scss'

const AboutUs = () => {
    return (
        <section className='about_wrapper' id='about'>
            <div className="section_content">
                <h2 className='title_block_dark'>Про нас</h2>
                <div className="about_text_block">
                    <p className="about_text">{'\u00A0'}{'\u00A0'}{'\u00A0'}<span>Лазерна порізка</span> - процес розкрою матеріалів лазером високої потужності. Через відсутність механічного впливу оброблюваний матеріал практично не деформується, що дає змогу виробляти якісні вироби складної форми.</p>
                    <p className="about_text">{'\u00A0'}{'\u00A0'}{'\u00A0'}До основних переваг лазерної порізки можна віднести <span>можливість обробки крихких матеріалів, високу точність порізки та легкість використання</span> (необхідний лише макет бажаного виробу).</p>
                    <p className="about_text">{'\u00A0'}{'\u00A0'}{'\u00A0'}Крім того, лазерна порізка - це можливість заощадити гроші завдяки раціональному використанню матеріалів і часу.</p>
                    <p className="about_text">{'\u00A0'}{'\u00A0'}{'\u00A0'}У своїй роботі ми використовуємо <span>лазер ЧПУ CO2</span>. Це універсальний верстат, оскільки, окрім порізки він виконує і гравіювання виробів.</p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;