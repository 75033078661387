import React from 'react';
import './Footer.scss'
import { navigation } from '../../Data/Navigation';
import { Link } from 'react-scroll';
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
    return (
        <footer className='footer_wrapper'>
            <div className='section_content footer_content'>
                <Link 
                    to='main'
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    className="footer_logo"
                />
                <div className="footer_links">
                    <nav className="footer_navigation_list">
                        {navigation.map((elem,index) => {
                            return <Link 
                                        to={elem.href}
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                        key={index}>{elem.link}
                                    </Link>
                        })}
                    </nav>
                </div>
                <div className="footer_social">
                    <a className='footer_navigation_social_link' href='https://instagram.com/s_las_ser?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr' target='_blank' rel='noreferrer'>
                        <FaInstagram />
                        <span>Слідкуйте за нами в instagram</span>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;