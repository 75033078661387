import React, { useEffect, useState } from 'react';
import './Header.scss'
import { navigation } from '../../Data/Navigation';
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-scroll';
import { Link as RouterLink} from 'react-router-dom'
import BurgerMenu from '../BurgerMenu/BurgerMenu';

const Header = () => {
    const [currentURL,setCurrentURL] = useState('')

    useEffect(()=> {
        setCurrentURL(window.location.href)
    },[window.location.href])

    return (
        <header className='header_wrapper'>
            <div className='header_content'>

                <RouterLink to='/' className="header_logo">
                </RouterLink>
                <div className="header_navigation">
                    <nav className="navigation_block">
                        <ul className="navigation_list">
                            {
                                navigation.map((elem,index) => {
                                    return <Link 
                                                to={elem.href}
                                                spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}
                                                key={index}>{elem.link}
                                            </Link>
                                })
                            }
                        </ul>
                        <div className='burger_menu'>
                            <BurgerMenu/>
                        </div>
                    </nav>
                    <div className='navigation_social'>
                        <a className='navigation_social_link' href='https://instagram.com/s_las_ser?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr' target='_blank' rel='noreferrer'>
                            <FaInstagram />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;