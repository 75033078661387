const materialPrice = {
    acrylic : [
        {
            size: 1.5,
            priceMeter: 4
        },
        {
            size: 2,
            priceMeter: 4.5
        },
        {
            size: 3,
            priceMeter: 6
        },
        {
            size: 4,
            priceMeter: 8
        },
        {
            size: 5,
            priceMeter: 9
        },
        {
            size: 6,
            priceMeter: 11
        },
        {
            size: 7,
            priceMeter: 14
        },
        {
            size: 8,
            priceMeter: 18
        },
        {
            size: 10,
            priceMeter: 22
        }
    ],
    plywood : [
        {
            size: 4,
            priceMeter: 7
        },
        {
            size: 5,
            priceMeter: 8
        },
        {
            size: 6,
            priceMeter: 9
        },
        {
            size: 8,
            priceMeter: 17
        },
        {
            size: 10,
            priceMeter: 22
        },
    ],
    polystyrene : [
        {
            size: 0.5,
            priceMeter: 2.5
        },
        {
            size: 0.75,
            priceMeter: 3.5
        },
        {
            size: 1,
            priceMeter: 3.8
        },
        {
            size: 1.5,
            priceMeter: 4.1
        },    {
            size: 2,
            priceMeter: 7.1
        },
        {
            size: 3,
            priceMeter: 7.8
        },
        {
            size: 4,
            priceMeter: 8.5
        },
        {
            size: 5,
            priceMeter: 11
        }
    ]
}

export {
    materialPrice
}