import React, { useRef } from 'react';
import './BurgerMenu.scss'
import { ChakraProvider } from '@chakra-ui/react';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from '@chakra-ui/react'
import { navigation } from '../../Data/Navigation';
import { Link } from 'react-scroll';
import { schedule } from '../../Data/Schedule';
import logo from '../../Img/Logos/svg/SLaser_logo_orange.svg'

const BurgerMenu = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <ChakraProvider>
            <div className='burder_menu_btn' ref={btnRef} onClick={onOpen}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Drawer
                isOpen={isOpen}
                placement='top'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton className='burger_close_btn'/>
                    <DrawerBody className='burger_content'>
                        <img src={logo} alt='S.Laser'/>
                        <ul className="burger_menu_list">
                            {navigation.map((elem,index) => {
                                return <Link 
                                            to={elem.href}
                                            spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}
                                            key={index}
                                            onClick={onClose}
                                        >
                                                {elem.link}
                                        </Link>
                            })}
                        </ul>
                        <div className="burger_phone_block">
                            {schedule?.phone?.map((elem,index) => {
                                return <a href={`tel:${elem}`} className="burger_phone" key={index}>{elem}</a>
                            })}
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </ChakraProvider>
    );
};

export default BurgerMenu;