import React, { useState } from 'react';
import './SliderWork.scss'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { sliderWork } from '../../Data/SliderWork';

const SliderWork = () => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className='wrapper_works_slider'>
            <Swiper
                style={{
                "--swiper-navigation-color": "#ea8735",
                "--swiper-pagination-color": "#ea8735",
                }}
                spaceBetween={10}
                loop={true}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className='main_swiper'
            >
                {sliderWork?.map((elem,index) => {
                    return <SwiperSlide key={index} className='swiper_slide_main' id={index}>
                                <img className='slider_info_img' src={elem?.image} alt='Work'/>
                            </SwiperSlide>
                })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className='second_swiper'
            >
                {sliderWork?.map((elem,index) => {
                    return <SwiperSlide key={index} className='swiper_slide' id={index}>
                                <img src={elem.image} alt='Work'/>
                            </SwiperSlide>
                })}
            </Swiper>
        </div>
    );
};

export default SliderWork;