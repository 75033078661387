import React from 'react';
import './Works.scss'
import SliderWork from '../SliderWork/SliderWork';
import { Link } from 'react-scroll';

const Works = () => {
    return (
        <section className='works_wrapper' id='works'>
            <div className="section_content">
                <h2 className='title_block_dark'>Наші роботи</h2>
                <div className="works_slider">
                    <SliderWork/>
                    <div className='works_slider_text'>
                        <p>Ласкаво просимо до нашої Галереї Майстерності, де кожна деталь створена з використанням передових технологій лазерної різки та гравіювання на станку ЧПУ СО2. Дозвольте нам подарувати вам подив на нашу колекцію вражаючих робіт, які поєднують мистецтво та технології в одне неповторне втілення.</p>
                        <p>Що Робить Наші Роботи Неперевершеними:</p>
                        <ul>
                            <li>Глибоке Гравіювання: Кожен виріб пройнятий мистецтвом глибокого гравіювання, що створює чіткі та елегантні зображення. Текстури та деталі виробів виходять на передній план, залишаючи незабутнє враження.</li>
                            <li>Висока Точність: Наш станок ЧПУ СО2 забезпечує високу точність різки, дозволяючи створювати складні та деталізовані форми. Кожен контур виходить бездоганно, надаючи роботі симетрію та гармонію.</li>
                            <li>Різноманіття Матеріалів: Виготовлення робіт на станку ЧПУ СО2 дозволяє працювати з <Link spy={true} smooth={true} offset={-100} duration={500} className='materiales_link' to='materiales'>різними матеріалами</Link>. Розкривайте свою творчість без обмежень.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Works;