import slide1 from '../Img/SliderWork/1.jpg'
import slide2 from '../Img/SliderWork/2.jpg'
import slide3 from '../Img/SliderWork/3.jpg'
import slide4 from '../Img/SliderWork/4.jpg'
import slide5 from '../Img/SliderWork/5.jpg'
import slide6 from '../Img/SliderWork/6.jpg'
import slide7 from '../Img/SliderWork/7.jpg'
import slide8 from '../Img/SliderWork/8.jpg'
import slide9 from '../Img/SliderWork/8.jpg'
import slide10 from '../Img/SliderWork/9.jpg'
import slide11 from '../Img/SliderWork/10.jpg'
import slide12 from '../Img/SliderWork/11.jpg'
import slide13 from '../Img/SliderWork/12.jpg'
import slide14 from '../Img/SliderWork/13.jpg'
import slide15 from '../Img/SliderWork/14.jpg'
import slide16 from '../Img/SliderWork/16.jpg'
import slide17 from '../Img/SliderWork/18.jpg'
import slide18 from '../Img/SliderWork/19.jpg'
import slide19 from '../Img/SliderWork/20.jpg'
import slide20 from '../Img/SliderWork/21.jpg'
import slide21 from '../Img/SliderWork/22.jpg'
import slide22 from '../Img/SliderWork/23.jpg'
import slide23 from '../Img/SliderWork/24.jpg'
import slide24 from '../Img/SliderWork/25.jpg'
import slide25 from '../Img/SliderWork/26.jpg'
import slide26 from '../Img/SliderWork/27.jpg'
import slide27 from '../Img/SliderWork/28.jpg'
import slide28 from '../Img/SliderWork/29.jpg'
import slide29 from '../Img/SliderWork/30.jpg'
import slide30 from '../Img/SliderWork/31.jpg'
import slide31 from '../Img/SliderWork/32.jpg'

const sliderWork = [
    {
        image: slide20
    },
    {
        image: slide21
    },
    {
        image: slide1
    },
    {
        image: slide2
    },
    {
        image: slide3
    },
    {
        image: slide4
    },
    {
        image: slide5
    },
    {
        image: slide6
    },
    {
        image: slide7
    },
    {
        image: slide8
    },
    {
        image: slide9
    },
    {
        image: slide10
    },
    {
        image: slide11
    },
    {
        image: slide12
    },
    {
        image: slide13
    },
    {
        image: slide14
    },
    {
        image: slide15
    },
    {
        image: slide16
    },
    {
        image: slide17
    },
    {
        image: slide18
    },
    {
        image: slide19
    },
    {
        image: slide22
    },
    {
        image: slide23
    },
    {
        image: slide24
    },
    {
        image: slide25
    },
    {
        image: slide26
    },
    {
        image: slide27
    },
    {
        image: slide28
    },
    {
        image: slide29
    },
    {
        image: slide30
    },
    {
        image: slide31
    }
]

export {
    sliderWork
}