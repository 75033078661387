import { Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Main from './Pages/Main/Main';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout/>}>
        <Route index element={<Main/>}/>
      </Route>
    </Routes>
  )
}

export default App;
