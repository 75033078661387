const materialies = [
    {
        materialName: 'Фанера',
    },
    {
        materialName: 'МДФ',
    },
    {
        materialName: 'ДВП',
    },
    {
        materialName: 'Акрил',
    },
    {
        materialName: 'Папір',
    },
    {
        materialName: 'Картон',
    },
    {
        materialName: 'Шкіра',
    },
    {
        materialName: 'Тканини',
    }
]

export {
    materialies
}