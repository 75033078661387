const ware = [
    {
        wareName: 'Продукція для торгових точок',
        descr: 'цінники, цінникотримачі, холдери, підставки, візитниці, буклетниці, таблички, вивіски тощо'
    },
    {
        wareName: 'Сувенірна продукція',
        descr: 'фоторамки, скриньки, підставки для канцелярії, календарі'
    },
    {
        wareName: 'Коробки з фанери',
        descr: 'різних форм, за наданими параметрами'
    },
    {
        wareName: 'Органайзери',
        descr: 'для косметики, спецій, канцелярії'
    },
    {
        wareName: 'Підставки для квітів',
        descr: 'виготовлення підставок за наданими параметрами та дизайном'
    },
    {
        wareName: 'Святкова продукція',
        descr: 'новорічна символіка, сніжинки, сердечка, написи тощо'
    },
    {
        wareName: 'Дитячі іграшки',
        descr: 'конструктори, алфавіти, бізіборди, будиночки для ляльок'
    },
    {
        wareName: 'Послуга лазерної порізки',
        descr: 'порізка доступного матеріалу відповідно до заданих параметрів'
    },
    {
        wareName: 'Послуга гравіювання',
        descr: 'наносимо на вироби логотипи, малюнки, написи, візерунки, фото'
    },
]

export {
    ware
}