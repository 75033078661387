import React from 'react';
import './Partners.scss'

const Partners = () => {
    return (
        <section className='parnetrs_wrapper' id='partners'>
            <div className="section_content">
                <h2 className='title_block_dark'>Партнери</h2>
                <div className='partners_content'>
                    <div className="partners_text">
                        <p className="partners_text_tit">Типография AVA Print</p>
                        <p className="partners_second_text">Цифровий друк дозволяє виготовляти повнокольорову поліграфічну продукцію від однієї копії, а також робити персоналізацію: наприклад, друкувати серійні номери на гарантійних талонах, імена на запрошення або ціни в меню. У такий спосіб виробляються візитки, календарики, флаєри, буклети, листівки, каталоги, бланки, а також наклейки на самоклеючому папері або плівці.</p>
                    </div>
                    <div>
                        <a href='http://avaprint.com.ua' className="partners_link" target='_blank' rel='noreferrer'>Перейти до партнера</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;