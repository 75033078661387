import React from 'react';
import './Materialies.scss'
import { materialies } from '../../Data/Materialies';

const Materialies = () => {
    return (
        <section className='materialies_wrapper' id='materiales'>
            <div className="section_content">
                <h2 className='title_block_light'>Матеріали, з якими ми працюємо</h2>
                <div className="materialies_box">
                    {materialies.map((elem,index) => {
                        return <span key={index}>{elem.materialName}</span>
                    })}
                </div>
            </div>
        </section>
    );
};

export default Materialies;